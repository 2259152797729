@import '~@cainiaofe/cn-ui-theme-light/token.scss';

.cn-multi-select {
  &-popup-container {
    display: flex;
    align-items: flex-start;
    background: $color-white;
    border: 1px solid #cfd2db;
    border-radius: $s-1;
  }

  // 弹层最外层
  &-menu-container {
    padding: $s-1;
    width: 230px;
    border-right: 1px solid #cfd2db;
  }

  /* 弹层搜索输入框 */
  &-header-search {
    margin-bottom: $s-2;
    line-height: initial;
    display: flex;
    align-items: center;

    & > &-content {
      width: 100%;
    }

    &-icon {
      &:hover {
        cursor: pointer;
        color: $color-primary;
      }
    }
  }

  &-menu-inner-scroll {
    max-height: 260px;
    overflow: auto;
  }

  /* 弹层 */
  &-menu.cn-next-ver {
    padding: 0;
    max-height: 250px;
    overflow: auto;
    border: none;

    .cn-multi-select-menu-empty-content {
      padding-left: $s-2;
      padding-right: $s-2;
      color: $color-text;
    }

    &.cn-multi-select-menu-virtual {
      max-height: $s-auto;
    }

    .cn-menu-item-text .cn-icon {
      vertical-align: middle;
    }
  }

  &-highlight {
    color: $color-primary;
    font-size: 12px;
  }
}

// 已选择列
.cn-multi-select-selected-container {
  width: 230px;
  padding: $s-1 $s-3;
}
.cn-multi-select-selected {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: $font-size;
    color: $color-text;

    border-bottom: 1px solid #cfd2db;
    height: $s-8;
    margin-bottom: $s-2;
  }

  &-body {
    max-height: 280px;
    overflow-y: auto;

    .cn-ui-tag {
      margin-bottom: $s-1;
      margin-right: $s-1;
    }
  }
}
