@import "~@cainiaofe/cn-ui/_fusion/lib/core/index-noreset.scss";
@import "~@cainiaofe/cn-ui/_fusion/lib/input/scss/variable.scss";
@import "~@cainiaofe/cn-ui/_fusion/lib/input/scss/mixin.scss";
@import "~@cainiaofe/cn-ui-theme-light/token.scss";
@import "./scss/mixin.scss";

.cn-multi-select {
  @include box-sizing;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;

  /* Select trigger */
  &-trigger {
    width: 200px;
    height: $s-8;
    min-width: 100px;
    outline: 0;
    border: 1px solid #cfd2db;
    border-radius: $radius;
    padding: 2px $s-2;
    transition: all $motion-duration-immediately $motion-linear;

    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .cn-multi-select-values {
      display: block;
      width: 100%;
      flex: 1 1 0;
      overflow: hidden;
      position: relative;
      white-space: nowrap;

      .cn-tag {
        display: inline-flex;
        align-items: center;
      }

      .cn-multi-select-placeholder {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;
        color: $color-text-tip;
        font-size: $font-size;
      }

      // 显示 “已选择 x/y”
      .cn-multi-select-max-tag-placeholder {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 0 4px 0 16px;
        background: linear-gradient(90deg, transparent, $color-white 10px);
      }
    }

    .cn-multi-select-clear {
      display: none;
    }

    &.cn-multi-has-clear:hover {
      .cn-multi-select-clear {
        display: inline-block;
      }
      .cn-multi-select-arrow {
        display: none;
      }
    }
  }

  &-arrow {
    cursor: pointer;
    width: auto !important;
    text-align: center;
    transition: all $motion-duration-immediately $motion-linear;
  }

  &.cn-multi-select-active {
    .cn-multi-select-arrow {
      transform: rotate(180deg);
    }
  }
}
