@import '@cainiaofe/cn-ui-theme-light/token.scss';

.cn-ui-ding-talk-scan {
  display: flex;
  align-items: center;
  gap: $s-1 $s-2;
  width: 200px;
  transition:
    box-shadow,
    border-color,
    background-color 100ms linear;
  border: 1px solid $color-border-dark;
  background-color: $color-white;
  padding: 6px $s-2;
  overflow: hidden;
  border-radius: $s-1;

  .cn-ui-icon {
    color: $color-text-tip;
  }
  
  &--disabled {
    color: $color-text-disabled;

    .cn-ui-icon {
      color: $color-text-disabled;
    }
  }

  &--readonly {
    flex-direction: column;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    align-items: initial;

    .cn-ui-icon {
      color: $color-text-tip;
    }

    &-item {
      display: inline-flex;
      gap: $s-1;
      align-items: center;
    }
  }

  &-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: $s-1;

    .cn-next-tag {
      white-space: wrap;
      border-radius: $s-2;

      .cn-next-tag-body {
        text-align: left;
      }

      .cn-next-tag-close-btn {
        height: auto;
      }
    }
  }

  &-input {
    outline: 0;
    line-height: 18px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    color: $color-text;
    border: none;
    border: 0;
    background-color: transparent;
    appearance: none;
    // 清除点击时的灰色背景
    -webkit-tap-highlight-color: transparent;

    &::placeholder {
      color: $color-text-disabled;
    }

    &:focus {
      border: 0;
      outline: none;
      background-color: transparent;
    }

    &:focus-visible {
      border: 0;
      outline: none;
      background-color: transparent;
    }

    &:focus-within {
      border: 0;
      outline: none;
      background-color: transparent;
    }

    &:disabled {
      color: $color-text-disabled;
      border: none;
      outline: none;
      // 兼容ios
      opacity: 1;
      background-color: inherit;

      &::placeholder {
        color: inherit;
      }
    }
  }
}
