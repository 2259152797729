// $cascader-select: "cascader-select";
@import "./variables.scss";
@import "~@cainiaofe/cn-ui-theme-light/token.scss";

.cn-address {
  min-width: $cn-address-min-width;
}

.cn-address-popup {
  .cn-next-cascader-inner {
    display: flex;
  }
  .cn-address-list {
    flex-shrink: 0;
    width: $cn-address-base-width;
  }

  .cn-address-menu-icon {
    top: 8px;
  }

  .cn-address-item {
    width: $cn-address-base-width;
  }

  .cn-address-footer {
    display: flex;
    border-top: 1px solid #dcdee3;
    background-color: #fff;

    .cn-address-footer-item {
      display: flex;
      flex: 1;

      .cn-address-footer-btn {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #333;
        margin: 0;
        border-radius: 0;
        border: 0;
        border-right: 1px solid #dcdee3;
      }
    }
  }

  .cn-address--mark-deletion {
    text-decoration: line-through;
    font-style: oblique;
  }
}

.cn-address-header-search {
  width: 100%;
  padding: 10px 0;
  background-color: $cn-address-search-background-color;

  .cn-address-header-search-inner {
    width: $cn-address-base-width - 20px;
    margin: 0 10px;
  }
}

.cn-address--search-loading-container {
  padding: 16px 40px;
  font-size: 12px;
}

.cn-address-search-text {
  background-color: $cn-address-search-background-color;
  color: $cn-address-search-text-color;
  text-align: center;
}

.cn-address-suffix-icon {
  margin-top: 2px;
  margin-right: 4px;
  color: #999;
}

.cn-address-select-tag-compact-inner {
  height: 100%;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  background: $color-white;
  color: $color-link;
  cursor: default;
}
