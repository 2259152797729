// CSS 书写规范 https://alidocs.dingtalk.com/i/nodes/Mk5evdR04jBV523gq4AgJQL3x2OlParn
// CSS 最佳实践 https://alidocs.dingtalk.com/i/nodes/YMyQA2dXW7gYo6Mzcwb1wjp3WzlwrZgb
@import "@cainiaofe/cn-ui-theme-light/token.scss";

.cn-ding-talk-camera-upload {
  .cn-ding-talk-camera-upload {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: $s-3;
    }

    &-item {
      width: $s-20;
      height: $s-20;
      position: relative;
      border-radius: $s-2;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;

      &-error {
        width: 100%;
        height: 100%;
        border-radius: $s-2;
        background-color: $color-bg-darker;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        font-size: $font-size;
        color: $color-white;
        line-height: 1;

        &-text {
          padding-top: $s-2;
        }
      }

      &-remove {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        width: 100%;
        height: $s-7;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        transition: opacity 100ms ease-in;
      }

      &:hover {
        .cn-ding-talk-camera-upload-item-remove {
          opacity: 1;
        }
      }

      &-uploading {
        width: 100%;
        height: 100%;
        border-radius: $s-2;
        background-color: $color-bg-darker;
        position: absolute;
        top: 0;
        right: 0;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
          font-size: $font-size;
          line-height: 1;
          font-weight: $font-weight-medium;
        }
      }

      &-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    &-add {
      border: 1px solid #d5d8dd;
      color: #d5d8dd;
      cursor: pointer;
      position: relative;
      transition: border 100ms ease-in;

      .cn-next-icon {
        transition: color 100ms ease-in;
      }

      &--disabled {
        cursor: not-allowed;
      }

      &:hover:not(&--disabled) {
        border-color: $color-primary;

        .cn-next-icon {
          color: $color-primary;
        }
      }

      &-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        border: 0;
        outline: 0;

        &:focus {
          outline: none;
        }
      }
    }

    &-disabled-tip {
      margin-top: $s-1;
      color: $color-text-disabled;
    }
  }
}