@import "~@cainiaofe/cn-ui-theme-light/token.scss";

.cn-next-cascader-select-dropdown {
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #dbdde4);
  border-radius: var(--popup-local-corner, 4px);
  box-shadow: var(--popup-local-shadow, 0px 2px 5px 0px rgba(32, 60, 153, 0.1));

  .cn-next-cascader {
    display: block;
    border: none;
    box-shadow: none;
  }
}

.cn-next-cascader {
  overflow: auto;
  border: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #dbdde4);
  border-radius: var(--cascader-menu-border-radius, 4px);

  .cn-next-cascader-inner:after {
    visibility: hidden;
    display: block;
    height: 0;
    font-size: 0;
    content: " ";
    clear: both;
  }

  .cn-next-cascader-menu-wrapper {
    float: left;
    overflow: auto;
    width: var(--cascader-menu-width, auto);
    min-width: var(--cascader-menu-min-width, 100px);
    height: var(--cascader-menu-height, 192px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .cn-next-cascader-menu-wrapper + .cn-next-cascader-menu-wrapper {
    border-left: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
      var(--cascader-menu-border-color, #dbdde4);
  }

  .cn-next-cascader-menu {
    position: relative;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    min-width: auto;
    min-height: 100%;

    &.cn-next-has-right-border {
      border-right: var(--cascader-menu-border-width, 1px)
        var(--line-solid, solid) var(--cascader-menu-border-color, #dbdde4);
    }
  }

  .cn-next-cascader-menu-icon-right.cn-next-icon {
    position: absolute;
    top: 0;
    right: 10px;
    color: var(--cascader-menu-icon-expand-color, #666666);
    padding-left: $s-1;
    margin-left: auto;

    &:hover {
      color: var(--cascader-menu-icon-hover-expand-color, #666666);
    }
  }

  .cn-next-cascader-menu-icon-right.cn-next-icon-remote {
    position: absolute;
    right: 10px;
    color: var(--cascader-menu-icon-expand-color, #666666);
    padding-left: $s-1;
    margin-left: auto;

    &:hover {
      color: var(--cascader-menu-icon-hover-expand-color, #666666);
    }
  }

  .cn-next-cascader-menu-item.cn-next-expanded {
    color: var(--cascader-menu-item-expanded-color, #333333);
    background-color: var(
      --cascader-menu-item-expanded-background-color,
      #eef1f7
    );

    .cn-next-cascader-menu-icon-right {
      color: var(--cascader-menu-icon-hover-expand-color, #666666);
    }

    .cn-next-cascader-menu-icon-loading {
      color: var(--color-brand1-6, #284cc0);
    }
  }

  .cn-next-cascader-menu-icon-expand {
    &.cn-next-icon:before,
    &.cn-next-icon .cn-next-icon-remote {
      width: var(--cascader-menu-icon-expand-size, 16px);
      font-size: var(--cascader-menu-icon-expand-size, 16px);
      line-height: inherit;
    }
  }

  .cn-next-cascader-menu-icon-loading {
    &.cn-next-icon:before,
    &.cn-next-icon .cn-next-icon-remote {
      width: 16px;
      font-size: 16px;
      line-height: inherit;
    }
  }

  .cn-next-cascader-filtered-list {
    height: 192px;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    overflow: auto;

    .cn-next-menu-item-inner {
      overflow: visible;
    }
  }

  .cn-next-cascader-filtered-item em {
    color: var(--color-brand1-6, #284cc0);
    font-style: normal;
  }

  .cn-next-cascader-select-not-found {
    padding: 0;
    border: none;
    box-shadow: none;
    overflow: auto;
    color: var(--color-text1-2, #999999);

    .cn-next-menu-item:hover {
      color: var(--color-text1-2, #999999);
      background: #ffffff;
      cursor: default;
    }
  }
}
