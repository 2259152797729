.cn-rule-tree {
  position: relative;

  .cn-next-form-item {
    margin-bottom: 0;
  }

  .cn-next-input.cn-next-select-inner {
    min-width: 100%;
  }

  .drop-area {
    border-radius: 3px;
    border: 1px dashed #1890ff;
    background: #91d5ff;
  }

  .drop-area-can-drop {
    background: #1890ff;
  }

  &-icon {
    color: #c7d0d9 !important;
  }

  .delete-icon {
    & > svg {
      vertical-align: -1.5px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    border: 1px dashed #c7d0d9;
    border-radius: 4px;
    margin-top: 4px;
    height: 32px;
    overflow-y: hidden;
  }

  .action-icon {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 8px;
    cursor: pointer;
    border-right: 1px dashed #c7d0d9;

    &:last-child {
      border-right: none;
    }
  }

  .action-icon-disabled {
    cursor: not-allowed !important;
  }

  .drag,
  .un-drag {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 0;
  }

  .drag {
    border-radius: 3px;
    padding: 4px 8px;
    background: #edf2f5;
    position: absolute;
  }

  .link {
    position: absolute;
    background-color: #c7d0d9;
    &-highlight {
      background-color: #1890ff;
    }
  }
}
