@import '~@cainiaofe/cn-ui-theme-light/token.scss';

$menu-prefix: '.cn-multi-select-menu';

#{$menu-prefix}.cn-next-ver {
  &.cn-next-menu {
    padding: 0;
    border: none;
  }

  #{$menu-prefix}-item.cn-next-menu-item {
    padding: 0 $s-2;
    border-radius: $radius;

    // 反选 hover 背景色覆盖
    &.cn-next-selected {
      &:focus {
        background-color: $color-secondary;
      }
    }

    .cn-next-menu-item-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      #{$menu-prefix}-item-header {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        max-width: 100%;

        // 多选状态下设置为 flex，为放置左侧 icon
        .cn-next-checkbox {
          margin-right: $s-2;
        }

        #{$menu-prefix}-item-text {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      #{$menu-prefix}-item-footer {
        #{$menu-prefix}-icon-right {
          color: #cfd2db;
        }
      }
    }
  }

  &-loading {
    height: 160px;
    width: 100%;
  }

  &-error {
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      text-align: center;

      &-text {
        font-size: $font-size;
        line-height: 1.5;
        color: $color-text-title3;
        margin-bottom: 8px;
      }
    }
  }
}
